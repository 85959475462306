import consumer from "./consumer";
import { initTimer, startTimer, stopTimer } from "../js/model/game";

document.addEventListener("turbolinks:load", function() {
  if (document.querySelector('meta[name="game"]') != null) {
    const { id } = document.querySelector('meta[name="game"]').dataset;
    const { instructionDisplayType } = document.querySelector(
      'meta[name="game"]'
    ).dataset;
    let instructionPosition;

    switch (instructionDisplayType) {
      case "prepend":
        instructionPosition = "afterbegin";
        break;
      case "append":
        instructionPosition = "beforeend";
        break;
      default:
        instructionPosition = "afterbegin";
    }

    const timer = document.querySelector('[data-attribute="timer"]');

    const hintsCounter = document.querySelector(
      '[data-behavior="hints-counter"]'
    );
    const hintsList = document.querySelector('[data-behavior="hints-list"]');
    const padlocksCounter = document.querySelector(
      '[data-behavior="padlocks-counter"]'
    );
    const padlocksList = document.querySelector(
      '[data-behavior="padlocks-list"]'
    );
    const instructions = document.querySelector(
      '[data-behavior="instructions"]'
    );

    consumer.subscriptions.create(
      {
        channel: "GameChannel",
        game_id: id
      },
      {
        received(data) {
          switch (data.type) {
            case "State":
              // update timer tag
              document.querySelector('meta[name="game"]').dataset.endTime =
                data.end_time;

              switch (data.action) {
                case "start":
                  // start timer
                  startTimer(timer, data.end_time, data.timer_danger_seconds);
                  console.log("start timer");
                  // hide welcome message
                  // show game started message
                  // append states list
                  // this.appendLine(data);
                  break;
                case "stop":
                  // stop timer
                  initTimer(timer, data.end_time, data.timer_danger_seconds);
                  console.log("stop timer");
                  // show game stop message
                  // append states list
                  // popup for admin (inside js of action)
                  break;
                case "pause":
                  // stop timer
                  stopTimer();
                  console.log("pause timer");
                  // show game pause message
                  // append states list
                  break;
                case "bonus_1":
                case "bonus_2":
                case "bonus_5":
                case "penalty_1":
                case "penalty_2":
                case "penalty_5":
                  switch (data.state) {
                    case "started":
                      stopTimer();
                      startTimer(
                        timer,
                        data.end_time,
                        data.timer_danger_seconds
                      );
                      break;
                    default:
                      stopTimer();
                      initTimer(
                        timer,
                        data.end_time,
                        data.timer_danger_seconds
                      );
                  }
                  console.log(`${data.action} timer while ${data.state}`);
                  break;
                default:
              }
              break;

            case "Hint":
              this.printHint(data);
              this.alertInstruction(data.hint_sound_file_url);
              this.appendHintsList(data);
              this.incrementCounter(hintsCounter);
              break;

            case "Padlock":
              this.printPadlock(data);
              this.alertInstruction(data.padlock_sound_file_url);
              this.appendPadlocksList(data);
              console.log("inside counter");
              this.incrementCounter(padlocksCounter);
              break;

            case "Error":
              this.printError(data);
              break;

            default:
              break;
          }
        },

        appendHintsList() {
          if (hintsList != null) {
            hintsList.insertAdjacentHTML("beforeend", "+");
          }
        },

        appendPadlocksList() {
          if (padlocksList != null) {
            padlocksList.insertAdjacentHTML("beforeend", "+");
          }
        },

        incrementCounter(elem) {
          if (elem != null) {
            const counter = elem;
            const updatedCount = parseInt(counter.innerText, 10) + 1;
            counter.innerHTML = updatedCount;
          }
        },

        printHint(data) {
          const html = this.setHint(data);
          this.printInstruction(
            html,
            data.instruction_display_seconds,
            data.instruction_display_permanent
          );
        },

        alertInstruction(soundUrl) {
          const sound = new Audio(soundUrl);
          // chrome 66 blocked play for unmuted video. We might add the sound inside hidden button/div then play them.
          // set to mute in the meantime.
          sound.muted = true;
          sound.play();
        },

        printPadlock(data) {
          const html = this.setPadlock(data);
          this.printInstruction(
            html,
            data.instruction_display_seconds,
            data.instruction_display_permanent
          );
        },

        printInstruction(
          html,
          instructionDisplaySeconds,
          instructionDisplayPermanent
        ) {
          if (instructions != null) {
            this.showInstruction();

            if (["prepend", "append"].includes(instructionDisplayType)) {
              instructions.insertAdjacentHTML(instructionPosition, html);
            } else {
              this.showInstruction();
              instructions.innerHTML = html;

              if (instructionDisplayPermanent === false) {
                const instructionDisplayMilliSeconds =
                  parseInt(instructionDisplaySeconds, 10) * 1000;
                setTimeout(
                  this.hideInstruction,
                  instructionDisplayMilliSeconds
                );
              }
            }
          }
        },

        hideInstruction() {
          instructions.style.opacity = "0";
        },

        showInstruction() {
          // instructions.style.opacity = "1";
          instructions.style.removeProperty("opacity");
        },

        setHint(data) {
          return `
          <article class="hint">
            <span class="speaker">LOGO</span>
            <span class="body">${data.name}</span>
            <span class="body">${data.description}</span>
          </article>
        `;
        },

        setPadlock(data) {
          return `
          <article class="padlock">
            <span class="speaker">LOGO</span>
            <span class="body">${data.name}</span>
            <span class="body">${data.description}</span>
          </article>
        `;
        }
      }
    );
  }
});

//   if (data.type === "Hint") {
//     // this.printHint(data)
//     this.appendHintsList(data);
//     this.incrementCounter("[data-behavior='game-hints-counter']");
//   } else if (data.type === "Padlock") {
//     // this.printHint(data)
//     this.appendPadlocksList(data);
//     this.incrementCounter("[data-behavior='game-padlocks-counter']");

//   } else if (data.type === "State") {
//     this.appendLine(data);
//     // this.appendPadlocksList(data.image_url)
//     // this.incrementPadlocksCounter
//   } else if (data.type === "Error") {
//     this.appendLine(data);
//   }
// },
