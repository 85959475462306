import Typed from "typed.js/lib/typed.min";

function initTyped(el) {
  let elementOptions = el.dataset.options;
  elementOptions = elementOptions ? JSON.parse(elementOptions) : {};
  const defaultOptions = {
    typeSpeed: 40,
    backSpeed: 40,
    backDelay: 1000,
    loop: true
  };
  const options = Object.assign(defaultOptions, elementOptions);
  /* eslint-disable no-new */
  new Typed(el, options);
}

document.addEventListener("turbolinks:load", () => {
  const toggle = document.querySelectorAll('[data-toggle="typed"]');

  if (typeof Typed !== "undefined" && toggle) {
    toggle.forEach(function(el) {
      initTyped(el);
    });
  }
});
