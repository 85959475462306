import { CountUp } from "countup.js/dist/countUp.min";

document.addEventListener("turbolinks:load", () => {
  (function() {
    const toggle = document.querySelector('[data-toggle="price"]');
    const DURATION = 1;

    function update(e) {
      const input = e.target;
      const { checked } = input;

      const { target } = input.dataset;
      const targets = Array.from(document.querySelectorAll(target));

      [].forEach.call(targets, function(el) {
        const { annual } = el.dataset;
        const { monthly } = el.dataset;
        const decimals = el.dataset.decimals ? el.dataset.decimals : null;
        const duration = el.dataset.duration ? el.dataset.duration : DURATION;
        const options = el.dataset.options
          ? JSON.parse(el.dataset.options)
          : null;

        const countUp = !checked
          ? new CountUp(el, monthly, annual, decimals, duration, options)
          : new CountUp(el, annual, monthly, decimals, duration, options);

        if (!countUp.error) {
          countUp.start();
        } else {
          console.error(countUp.error);
        }
      });
    }

    if (typeof CountUp !== "undefined" && toggle) {
      toggle.addEventListener("change", function(e) {
        update(e);
      });
    }
  })();
});
