document.addEventListener("turbolinks:load", () => {
  (function() {
    const navbar = document.querySelector(".navbar");
    const isTogglable = navbar
      ? navbar.classList.contains("navbar-togglable")
      : false;
    let isLight = false;

    function makeNavbarLight() {
      if (!isLight && isTogglable) {
        navbar.classList.remove("navbar-dark");
        navbar.classList.add("navbar-light");
        navbar.classList.add("bg-white");
        navbar.classList.add("border-bottom");

        isLight = true;
      }
    }

    // Repaint hack for Safari overscroll bug
    function repaintNav() {
      navbar.style.display = "none";
      /* eslint-disable no-unused-expressions */
      navbar.offsetHeight;
      navbar.style.display = "block";
    }

    function makeNavbarDark() {
      if (isLight && isTogglable) {
        navbar.classList.remove("navbar-light");
        navbar.classList.remove("bg-white");
        navbar.classList.remove("border-bottom");
        navbar.classList.add("navbar-dark");

        repaintNav();

        isLight = false;
      }
    }

    function toggleNavbar() {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0 && !isLight) {
        makeNavbarLight();
      } else if (scrollTop === 0 || (scrollTop < 0 && isLight)) {
        makeNavbarDark();
      }
    }

    if (navbar && isTogglable) {
      ["load", "scroll"].forEach(function(event) {
        window.addEventListener(event, function(e) {
          const { type } = e;

          toggleNavbar(type);
        });
      });
    }
  })();
});
