/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'application' %> (and
// <%= stylesheet_pack_tag 'application' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from "vue";
import TurbolinksAdapter from "vue-turbolinks";
import VueResource from "vue-resource";
// import App from "../app.vue";

import "bootstrap/dist/js/bootstrap.min";
import "jarallax/dist/jarallax.min";

import "js";
import destroyField from "js/destroy_field";
import createField from "js/create_field";
import animateTitle from "js/animate_title";

import "src/fonts";
import "src/css";

require.context("../images", true);
// const images = require.context("../images", true);
// const imagePath = (name) => images(name, true)

Vue.use(VueResource);
Vue.use(TurbolinksAdapter);

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

document.addEventListener("turbolinks:load", () => {
  // feather.replace();

  if (document.querySelector("[data-behavior='destroy-field']") != null) {
    document
      .querySelectorAll("[data-behavior='destroy-field']")
      .forEach(function(elem) {
        destroyField(elem);
      });
  }

  if (document.querySelector("[data-behavior='create-field']") != null) {
    createField(
      "[data-behavior='card-hints']",
      "[data-behavior='create-field']",
      "hints"
    );
    createField(
      "[data-behavior='card-padlocks']",
      "[data-behavior='create-field']",
      "padlocks"
    );
  }

  let pageTitle = document.title;
  if (pageTitle === null) {
    pageTitle = "Escap";
  }
  animateTitle("backward", 0, pageTitle);

  // const token = document
  //   .querySelector('meta[name="csrf-token"]')
  //   .getAttribute("content");
  // console.log(token);
  // Vue.http.headers.common["X-CSRF-Token"] = token;
  // const element = document.querySelector('[data-behavior="form-mission"]');
  // if (element != null) {
  //   console.log("form detected");
  //   const mission = JSON.parse(element.dataset.mission);
  //   const hintsAttributes = JSON.parse(element.dataset.hintsAttributes);
  //   hintsAttributes.forEach(function(hint) {
  //     // eslint-disable-next-line no-underscore-dangle
  //     hint._destroy = null;
  //   });
  //   mission.hintsAttributes = hintsAttributes;
  // const app = new Vue({
  //   el: element,
  //   data: {
  //     mission
  //   },
  //   template: "<App :mission='mission' />",
  //   components: { App }
  // });
  // const app = new Vue({
  //   render: h => h(App)
  // }).$mount();
  // document.body.appendChild(app.$el);
  // }
});

// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'application' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>

// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
