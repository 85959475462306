import { CountUp } from "countup.js/dist/countUp.min";

document.addEventListener("turbolinks:load", () => {
  (function() {
    const toggle = document.querySelectorAll('[data-toggle="countup"]');

    function init(elem) {
      const startVal = elem.dataset.from ? +elem.dataset.from : null;
      const endVal = elem.dataset.to ? +elem.dataset.to : null;
      const decimals = elem.dataset.decimals ? +elem.dataset.decimals : null;
      const duration = elem.dataset.duration ? +elem.dataset.duration : null;
      const options = elem.dataset.options
        ? JSON.parse(elem.dataset.options)
        : null;
      const countUp = new CountUp(
        elem,
        startVal,
        endVal,
        decimals,
        duration,
        options
      );

      if (!countUp.error) {
        countUp.start();
        elem.classList.add("counted");
      } else {
        console.error(countUp.error);
      }
    }

    if (typeof CountUp !== "undefined" && toggle) {
      [].forEach.call(toggle, function(el) {
        if (el.getAttribute("data-aos-id") !== "countup:in") {
          init(el);
        }
      });
    }

    document.addEventListener("aos:in:countup:in", function(e) {
      if (e.detail instanceof Element) {
        init(e.detail);
      } else {
        const toCount = document.querySelectorAll(
          '.aos-animate[data-aos-id="countup:in"]:not(.counted)'
        );
        [].forEach.call(toCount, function(el) {
          init(el);
        });
      }
    });
  })();
});
