function animateTitle(direction, cursor, pageTitle) {
  const titleLength = pageTitle.length;
  const speed = 300;
  let offset = cursor;
  let splitTitle;
  let offsetTitleLength;

  if (direction === "backward") {
    if (offset > 0) {
      offset -= 1;
      offsetTitleLength = titleLength - offset;
      splitTitle = pageTitle.substr(offsetTitleLength, offset);
    } else {
      offset = titleLength;
      splitTitle = pageTitle.substr(0, offset);
    }
    document.title = splitTitle;
    window.setTimeout(function() {
      animateTitle(direction, offset, pageTitle);
    }, speed);
  } else if (direction === "forward") {
    if (offset < titleLength) {
      offset += 1;
    } else {
      offset = 0;
    }
    splitTitle = pageTitle.substr(0, offset);
    document.title = splitTitle;
    window.setTimeout(function() {
      animateTitle(direction, offset, pageTitle);
    }, speed);
  }
}

export { animateTitle as default };
