import { toggleAllDisplayMinutes } from "js/toggle_display_minutes";

document.addEventListener("turbolinks:load", function() {
  if (
    document.querySelector('[data-behavior="card-hints"]') != null ||
    document.querySelector('[data-behavior="card-padlocks"]') != null
  ) {
    toggleAllDisplayMinutes();
  }

  if (document.querySelector('[data-behavior="select-fonts"]') != null) {
    const selectFonts = document.querySelector(
      '[data-behavior="select-fonts"]'
    );
    selectFonts.addEventListener("change", function() {
      document.querySelector('[data-behavior="sample-font"]').style.fontFamily =
        selectFonts.value;
    });
  }

  if (
    document.querySelector('[data-behavior="select-instruction-font-color"]') !=
    null
  ) {
    const selectInstructionFontColor = document.querySelector(
      '[data-behavior="select-instruction-font-color"]'
    );
    selectInstructionFontColor.addEventListener("change", function() {
      document.querySelector('[data-behavior="sample-font"]').style.color =
        selectInstructionFontColor.value;
    });
  }
});
