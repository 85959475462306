export function toggleDisplayMinutes(e) {
  const displayMinutes = e
    .closest(".form-row")
    .querySelector('input[name$="[display_minutes]"]').parentElement;
  if (e.checked === true) {
    displayMinutes.classList.add("d-none");
  } else {
    displayMinutes.classList.remove("d-none");
  }
}

export function toggleAllDisplayMinutes() {
  const displayPermanentSwitches = document.querySelectorAll(
    'input[type=checkbox][name$="[display_permanent]"]'
  );

  displayPermanentSwitches.forEach(function(e) {
    toggleDisplayMinutes(e);
    e.addEventListener("click", function() {
      toggleDisplayMinutes(e);
    });
  });
}
