// import "@uppy/core/dist/style.css";
// import "@uppy/drag-drop/dist/style.min.css";
// import "@uppy/progress-bar/dist/style.min.css";

const Uppy = require("@uppy/core");
// const FileInput = require('@uppy/file-input');
const DragDrop = require("@uppy/drag-drop");
const ProgressBar = require("@uppy/progress-bar");
const StatusBar = require("@uppy/status-bar");
const AwsS3 = require("@uppy/aws-s3");
const ThumbnailGenerator = require("@uppy/thumbnail-generator");

const onUploadSuccess = hiddenInput => {
  const input = hiddenInput;
  return file => {
    console.log("UPLOAD SUCCESS!");
    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id: file.meta.key.match(/^cache\/(.+)/)[1],
      storage: "cache",
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type
      }
    };
    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    input.value = JSON.stringify(uploadedFileData);
  };
};

function ImageUpload(fileInput) {
  const formGroup = fileInput.parentNode;
  const hiddenInput = formGroup.querySelector(
    '[data-attribute="upload-hidden"]'
  );
  // const imagePreview = formGroup.querySelector('[data-behavior="upload-preview"] img');
  const progressBar = formGroup.querySelector(
    '[data-behavior="upload-progress-bar"]'
  );
  const statusBar = formGroup.querySelector(
    '[data-behavior="upload-status-bar"]'
  );
  const dragDrop = formGroup.querySelector(
    '[data-behavior="upload-drag-drop"]'
  );

  // remove file input in favour of Uppy's
  formGroup.removeChild(fileInput);

  const ImageUploader = new Uppy({
    debug: true,
    autoProceed: true,
    restrictions: { maxNumberOfFiles: 1 }
  });

  ImageUploader.use(AwsS3, { companionUrl: "/" })
    .use(DragDrop, { target: dragDrop })
    .use(ProgressBar, { target: progressBar, hideAfterFinish: false })
    .use(ThumbnailGenerator, { thumbnailWidth: 600 })
    .use(StatusBar, {
      target: statusBar,
      hideCancelButton: true,
      hidePauseResumeButton: true
    })
    .on("thumbnail:generated", (_file, preview) => {
      // show preview of the image using URL from thumbnail generator
      // imagePreview.src = preview;
      dragDrop.style.backgroundImage = `url(${preview})`;
      // dragDrop.style.backgroundSize = 'cover';
      // dragDrop.style.backgroundRepeat = 'no-repeat';
    })
    .on("upload-success", onUploadSuccess(hiddenInput));
}

document.addEventListener("turbolinks:load", () => {
  document
    .querySelectorAll('[data-behavior="upload-image"]')
    .forEach(function(fileInput) {
      ImageUpload(fileInput);
    });
});
