import AOS from "aos/dist/aos";
import "aos/dist/aos.css";

document.addEventListener("turbolinks:load", () => {
  (function() {
    function init() {
      const options = {
        duration: 700,
        easing: "ease-out-quad",
        once: true,
        startEvent: "load"
      };
      AOS.init(options);
    }

    if (typeof AOS !== "undefined") {
      init();
    }
  })();
});
