import destroyField from "./destroy_field";
import { toggleAllDisplayMinutes } from "./toggle_display_minutes";
import updateFieldCounter from "./update_field_counter";

function createField(container, elem, type) {
  const containerObj = document.querySelector(container);

  if (containerObj != null) {
    const elemObj = containerObj.querySelector(elem);

    if (elemObj != null) {
      elemObj.addEventListener("click", function(e) {
        const collection = containerObj.querySelector(
          `[data-behavior='${type}-collection']`
        );
        e.preventDefault();

        const time = new Date().getTime();
        const regexp = new RegExp(this.dataset.id, "g");

        collection.insertAdjacentHTML(
          "beforeend",
          this.dataset.fields.replace(regexp, time)
        );

        const dField = collection.lastElementChild.querySelector(
          "[data-behavior='destroy-field']"
        );
        destroyField(dField);

        // document
        //   .querySelectorAll("[data-behavior='destroy-field']")
        //   .forEach(function(dField) {
        //     destroyField(dField);
        //   });

        toggleAllDisplayMinutes();

        let elemsCount = 0;
        if (collection != null) {
          elemsCount = collection.querySelectorAll(
            "article[data-behavior='item']"
          ).length;
        }
        updateFieldCounter(type, elemsCount);
      });
    }
  }
}

export { createField as default };
