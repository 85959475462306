import updateFieldCounter from "./update_field_counter";

function destroyField(elem) {
  elem.addEventListener("click", function(e) {
    e.preventDefault();
    this.previousElementSibling.value = 1;
    const type = `${this.dataset.attribute}s`;
    const item = this.closest("article[data-behavior='item']");
    item.style.display = "none";
    item.dataset.behavior = "deleted-item";
    const collection = this.closest(`[data-behavior='${type}-collection']`);

    let elemsCount = 0;
    if (collection != null) {
      elemsCount = collection.querySelectorAll("article[data-behavior='item']")
        .length;
    }
    updateFieldCounter(type, elemsCount);
  });
}

export { destroyField as default };
