let timeInterval;

// PARSE DATE
export function formatTimer(endTime) {
  let difference = Date.parse(endTime) - new Date().getTime(); // milliseconds difference between 2 dates
  difference = difference < 0 ? 0 : difference;
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  let hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((difference % (1000 * 60)) / 1000);
  //  milliseconds = Math.floor((difference % (1000 * 60)) / 100);
  hours = (hours < 10 ? "0" : "") + hours;
  minutes = (minutes < 10 ? "0" : "") + minutes;
  seconds = (seconds < 10 ? "0" : "") + seconds;
  // milliseconds = if (milliseconds < 100) {
  //                   milliseconds < 10 ? "00" : "0";
  //                 } else {
  //                   ""
  //                 } + milliseconds;
  return { difference, days, hours, minutes, seconds };
}

// STOP
export function stopTimer() {
  clearInterval(timeInterval);
}

// INIT
export function initTimer(elem, endTime, dangerZoneSeconds) {
  const t = formatTimer(endTime);
  const timer = elem;
  timer.innerHTML = `${t.hours}:${t.minutes}:${t.seconds}`;
  if (t.difference <= 0) {
    stopTimer();
  } else if (t.difference <= dangerZoneSeconds) {
    timer.style.color = "red";
  }
}

// START
export function startTimer(elem, endTime, dangerZoneSeconds) {
  initTimer(elem, endTime, dangerZoneSeconds);
  timeInterval = setInterval(function() {
    initTimer(elem, endTime);
  }, 1000);
}

document.addEventListener("turbolinks:load", function() {
  if (document.querySelector('meta[name="game"]') != null) {
    const { state } = document.querySelector('meta[name="game"]').dataset;
    const { endTime } = document.querySelector('meta[name="game"]').dataset;
    const timer = document.querySelector('[data-attribute="timer"]');
    const { dangerZoneSeconds } = document.querySelector(
      'meta[name="game"]'
    ).dataset;

    switch (state) {
      case "pending":
        // do nothing
        break;
      case "started":
        // init timer with remaining
        // start timer
        startTimer(timer, endTime, dangerZoneSeconds);
        break;
      case "paused":
        // stop timer
        stopTimer();
        break;
      case "stopped":
        // timer auto stop when 0 time left
        break;
      default:
        break;
    }
  }

  if (document.querySelector('[data-behavior="games-edit"]') != null) {
    const sectionLog = document.querySelector('[data-behavior="game-log"]');

    if (sectionLog != null) {
      sectionLog.style.display = "none";
    }

    document
      .querySelectorAll('[data-behavior="link-js"]')
      .forEach(function(link) {
        link.addEventListener("click", function(e) {
          e.preventDefault();
          document.querySelectorAll("section").forEach(function(elem) {
            const section = elem;
            section.style.display = "none";
          });
          document.querySelector(
            `[data-behavior="${link.dataset.attribute}"]`
          ).style.display = "flex";
        });
      });
  }
});
